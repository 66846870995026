import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ContactUsContainer from '../containers/ContactUsContainer'

const ContactUsPage = (props) => (
  <Layout 
  pageInfo={{ pageName: "Contact Us" }} 
  styleClass="home-page" 
  mainClass="main-about"
  location={props.location.pathname}>
    <SEO title="Contact Us" />
    <ContactUsContainer />
  </Layout>
)

export default ContactUsPage
